import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import firebase from 'firebase/app'
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth'

import { WindowLocation } from '@reach/router'

import { Link, navigate } from 'gatsby'

import CircularProgress from '@material-ui/core/CircularProgress'

type EnterProps = {
  location: WindowLocation
  firebase: firebase.FirebaseApp
}

const Enter: FunctionComponent<EnterProps> = ({
  location,
  firebase: firebaseApp,
}) => {
  const [loginAttemptFailed, setLoginAttemptFailed] = React.useState<
    boolean | undefined
  >(undefined)
  React.useEffect(() => {
    const asyncEffect = async (user: unknown) => {
      if (user) {
        await navigate('/admin')
        return
      }

      // Check and see if we're signing in from a link
      if (isSignInWithEmailLink(getAuth(firebaseApp), location.href)) {
        const address = window.localStorage.getItem('emailForSignIn')
        if (!address) {
          // TODO(alex): Handle the case where the patron tries to sign in with a
          // different browser than the one they sent the link from.
          setLoginAttemptFailed(true)
          return
        }

        try {
          await signInWithEmailLink(
            getAuth(firebaseApp),
            address,
            location.href,
          )
          window.localStorage.removeItem('emailForSignIn')
        } catch (e) {
          console.log(
            'An error occurred when trying to sign in via email link:',
            e,
          )
        }

        setLoginAttemptFailed(false)
        await navigate('/admin')
      } else {
        setLoginAttemptFailed(true)
      }
    }

    // Call effect once auth is resolved.
    const unsubscribeOnAuthStateChanged = getAuth(
      firebaseApp,
    ).onAuthStateChanged((user) => {
      void asyncEffect(user)
    })

    return () => {
      unsubscribeOnAuthStateChanged()
    }
  })

  return (
    <>
      <Helmet title="Entering HostBurro" />
      <h1>Signing In</h1>
      {loginAttemptFailed ? (
        <>
          This sign in URL has already been used or is invalid, please try{' '}
          <Link to="/sign-in">signing in again</Link>.
        </>
      ) : (
        <CircularProgress size={24} />
      )}
    </>
  )
}

export default Enter
